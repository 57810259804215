exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analizy-wytrzymalosciowe-js": () => import("./../../../src/pages/analizy-wytrzymalosciowe.js" /* webpackChunkName: "component---src-pages-analizy-wytrzymalosciowe-js" */),
  "component---src-pages-centrum-wiedzy-js": () => import("./../../../src/pages/centrum-wiedzy.js" /* webpackChunkName: "component---src-pages-centrum-wiedzy-js" */),
  "component---src-pages-doradztwo-techniczne-js": () => import("./../../../src/pages/doradztwo-techniczne.js" /* webpackChunkName: "component---src-pages-doradztwo-techniczne-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-optymalizacja-js": () => import("./../../../src/pages/optymalizacja.js" /* webpackChunkName: "component---src-pages-optymalizacja-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-prototypowanie-js": () => import("./../../../src/pages/prototypowanie.js" /* webpackChunkName: "component---src-pages-prototypowanie-js" */),
  "component---src-pages-rotomoulding-js": () => import("./../../../src/pages/rotomoulding.js" /* webpackChunkName: "component---src-pages-rotomoulding-js" */),
  "component---src-pages-skanowanie-3-d-js": () => import("./../../../src/pages/skanowanie3d.js" /* webpackChunkName: "component---src-pages-skanowanie-3-d-js" */),
  "component---src-pages-uslugi-projektowe-js": () => import("./../../../src/pages/uslugi-projektowe.js" /* webpackChunkName: "component---src-pages-uslugi-projektowe-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

